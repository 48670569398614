




















































































































































































import { Component, Emit, Prop, PropSync, Provide, Vue, Watch } from 'vue-property-decorator'
import ChatComponent from '@/components/ChatComponent.vue'
import { DEFAULT_PREVIEW_SCALE, MCASSET_VERSION } from '../../vars'

import {FONTS} from "../../fonts"

@Component({
    components: {
        ChatComponent
    }
})
export default class ChatCreator extends Vue {
    compCounter: number = 1
    components: ChatComponent[] = [];

    backgroundTextures: any = {
        dirt: {
            image: 'https://cdn.jsdelivr.net/gh/InventivetalentDev/minecraft-assets@' + MCASSET_VERSION + '/assets/minecraft/textures/block/dirt.png',
            color: '#a5522a'
        },
        grass: {
            image: 'https://i.imgur.com/AQeXKzD.png',
            color: '#1bba12'
        },
        stone: {
            image: 'https://cdn.jsdelivr.net/gh/InventivetalentDev/minecraft-assets@' + MCASSET_VERSION + '/assets/minecraft/textures/block/stone.png',
            color: '#838383'
        },
        snow: {
            image: 'https://cdn.jsdelivr.net/gh/InventivetalentDev/minecraft-assets@' + MCASSET_VERSION + '/assets/minecraft/textures/block/snow.png',
            color: '#f5f5f5'
        },
        obsidian: {
            image: 'https://cdn.jsdelivr.net/gh/InventivetalentDev/minecraft-assets@' + MCASSET_VERSION + '/assets/minecraft/textures/block/obsidian.png',
            color: '#222222'
        }
    };
    backgroundTexture: string = 'stone';

    availableFonts: string[] = [];
    fontData: any = {};

    packLinks: any = {};

    previewScale: number = DEFAULT_PREVIEW_SCALE;
    shadow: boolean = true;
    //
    // @Watch('components', { deep: true })
    // componentChanged (val: any, oldVal: any) {
    //     window.console.log(val)
    // }

    // get components () {
    //     return this.components_.map((c: any) => {
    //         c.fontUrl = 'https://cdn.jsdelivr.net/gh/InventivetalentDev/minecraft-assets@' + MCASSET_VERSION + '/assets/minecraft/textures/' + c.font
    //         return c
    //     })
    // }

    // fontUrl (font: string) {
    //     return 'https://cdn.jsdelivr.net/gh/InventivetalentDev/minecraft-assets@' + MCASSET_VERSION + '/assets/minecraft/textures/' + font
    // }

    get javaComponentJson() {
        const base: any = {
            text: "",
            extra: []
        }
        this.components.forEach((comp: any) => {
            base.extra.push(comp.getJson())
        })
        return JSON.stringify(base);
    }

    get backgroundTextureKeys() {
        return Object.keys(this.backgroundTextures);
    }

    hasUnderlineOrStrikethrough(): boolean {
        for (let comp of this.components) {
            let a = comp as any;
            if(a.underlined||a.strikethrough)return true;
        }
        return false;
    }

    backgroundStyle() {
        let tex = this.backgroundTextures[this.backgroundTexture];
        return {
            backgroundImage: 'url(' + tex.image + ')',
            backgroundColor: tex.color
        }
    }

    backgroundTextureChange() {

    }

    isLineBreak(c: string) {
        return c === '\n'
    }

    onText(data: any) {
        window.console.log('onText: ' + JSON.stringify(data))
        this.setCompProp(data.index, 'text', data.value)
    }

    onFont(data: any) {
        window.console.log('onFont: ' + JSON.stringify(data))
        this.setCompProp(data.index, 'font', data.value)
    }

    onColor(data: any) {
        window.console.log('onColor: ' + JSON.stringify(data))
        this.setCompProp(data.index, 'color', data.value)
    }

    onBold(data: any) {
        this.setCompProp(data.index, 'bold', data.value)
    }

    onItalic(data: any) {
        this.setCompProp(data.index, 'italic', data.value)
    }

    onUnderlined(data: any) {
        this.setCompProp(data.index, 'underlined', data.value)
    }

    onStrikethrough(data: any) {
        this.setCompProp(data.index, 'strikethrough', data.value)
    }

    onObfuscated(data: any) {
        this.setCompProp(data.index, 'obfuscated', data.value)
    }

    removeComponent(data: any) {
        window.console.log('removeComponent: ' + JSON.stringify(data))
        this.components.splice(data.index, 1);
        this.$forceUpdate()
    }

    setCompProp(index: number, prop: string, value: any) {
        const updated = this.components[index] as any
        updated[prop] = value
        this.$set(this.components, index, updated)
    }

    styleForComponent(comp: any, character: string) {
        return {
            fontSize: 20
        }
    }

    @Emit()
    addComponent() {
        const comp = new ChatComponent() as any
        comp.index = this.compCounter++;
        this.components.push(comp)
    }

    addFont(name: string, data: any) {
        let split = name.split(":");
        fetch("/font_data/" + split[0] + "/" + split[1] + "/sizes.json").then(res => res.json()).then(sizes => {
            data.sizes = sizes;
            this.fontData[name] = data
            this.availableFonts.push(name);
            window.console.log("Font " + name + " added")
        })
    }

    mounted(): void {
        window.console.log(FONTS)
        FONTS.forEach((font: any) => {
            fetch(font.data).then((res) => res.json()).then((data) => {
                this.addFont(font.name, data);
                if (font.link) {
                    this.packLinks[font.name] = font.link;
                }
            });
        })
    }
}
